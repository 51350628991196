@import "../vendor/normalize.css";
.page {
  background-color: #090e24;
  transition: 0.3s;
  min-height: 100vh;
  overflow: hidden;
}

#page::-webkit-scrollbar {
  width: 10px;
  background: #1c3861;
}
#page::-webkit-scrollbar-thumb {
  background: #c1002a;
  border-radius: 10px;
}

.page_light {
  background-color: #fff;
  transition: 0.3s;
  overflow: auto;
}

.section {
  margin-right: 20px;
  margin-bottom: 50px;
}

.header {
  background-color: #090e24;
  display: flex;
  height: 60px;
  width: 100%;
  margin-bottom: 15px;
  position: fixed;
  z-index: 100000;
}

.header__logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c1002a;
  width: 55px;
  cursor: pointer;
}

.header__logo {
  max-width: 50%;
  height: 30px;
}

.header__title-wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 26px;
  justify-content: center;
}

.header__title {
  margin: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
}

.header__description {
  margin: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
}

.sidebar {
  display: flex;
  overflow: auto;
  background-color: #090e24;
  height: calc(100vh - 60px);
  transition: 0.3s;
  position: relative;
  z-index: 10000;
  width: 300px;
}
.sidebar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.sidebar_disabled {
  position: fixed;
  width: auto;
}
.sidebar_disabled::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.menu {
  padding: 0;
  width: 300px;
  transition: 0.2s;
}

.menu_disabled {
  transition: 0.2s;
  width: 65px;
  overflow: hidden;
}
.menu_disabled .menu__text-wrapper {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
}
.menu_disabled .menu__text {
  pointer-events: none;
}

.menu__item {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0 0 10px;
  align-items: flex-start;
  transition: 0.3s;
}
.menu__item:last-of-type {
  margin: 0;
}
.menu__item:hover .menu__subitem-container {
  visibility: visible;
  pointer-events: auto;
  transform: translate(0, 80px);
  transition: 0.2s;
  opacity: 1;
}

.menu__ico-wrapper {
  display: flex;
  margin-left: 5px;
  min-width: 50px;
  height: 100%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.menu__ico {
  width: 25px;
}
@media screen and (max-width: 1440px) {
  .menu__ico {
    width: 20px;
  }
}

.menu__text-wrapper {
  margin-left: 10px;
  max-width: 230px;
  display: flex;
  align-items: center;
}

.menu__text {
  margin: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  width: 230px;
}

.menu__link {
  width: 100%;
  text-decoration: none;
  height: inherit;
  display: inherit;
  transition: inherit;
  align-items: inherit;
}

.menu__item-container {
  display: flex;
  height: 50px;
  align-items: center;
  width: 100%;
  transition: 0.2s;
}
.menu__item-container:hover {
  transition: 0.2s;
  background-color: #101a46;
  border-left-color: #101a46;
}
.menu__item-container:hover::after {
  position: absolute;
  content: "";
  left: 5px;
  border-top: 8px solid transparent;
  border-left: 6px solid;
  border-left-color: inherit;
  border-bottom: 8px solid transparent;
}
@media screen and (max-width: 1440px) {
  .menu__item-container {
    height: 30px;
  }
}

.menu__item-container_active {
  border-left-color: #c1002a;
  background-color: #c1002a;
  position: relative;
}
.menu__item-container_active::after {
  position: absolute;
  content: "";
  left: 5px;
  border-top: 8px solid transparent;
  border-left: 6px solid;
  border-left-color: inherit;
  border-bottom: 8px solid transparent;
}

.menu__item_opened {
  transition: 0.3s;
}

.menu__subitem-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 245px;
  position: absolute;
  transform: translate(0, 80px);
  top: -30px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.2s;
}
@media screen and (max-width: 1440px) {
  .menu__subitem-container {
    top: -50px;
  }
}

.menu__subitem {
  height: 40px;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #101a46;
  transition: 0.3s;
}
.menu__subitem:hover {
  background-color: #1c3861;
  transition: 0.3s;
}

.menu__subitem-link {
  padding-left: 15px;
  margin: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-decoration: none;
}

.main {
  padding-top: 80px;
  min-height: calc(100vh - 80px);
  display: flex;
}

.home-screen {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.home-screen__image {
  max-width: 100%;
  max-height: calc(100% - 65px);
}

.section-heading {
  height: 50px;
  display: flex;
  align-items: center;
  background-color: #090e24;
  margin-bottom: 20px;
}

.section-heading__title {
  margin: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  margin-left: 20px;
}

.section__wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  width: calc(100vw - 80px);
}

.section__additional-content {
  display: flex;
  border: 1px solid #c1002a;
  padding: 50px 20px;
}

.section__image {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 50px;
}


.section__mozaik	{ display:flex; flex-wrap:wrap; }
.section__mozaik .section__image	{ width:25%; display:block; box-shadow:0 0 10px 0 rgba(0, 0, 0, 0); margin-bottom:0; transition:transform .3s, box-shadow .3s; }
.section__mozaik .section__image:hover	{ box-shadow:0 10px 10px 0 rgba(0, 0, 0, .25); transform:translateY(-10px); }

.flex-wrapper { display:flex; width:100%; }
#benchmarks .section__content { font-family:"Open Sans", sans-serif; font-size:18px; color:#101a46; }
.flex-wrapper .twocols-col { width:50%; }
.flex-wrapper .twocols-col.center { text-align:center; }
.flex-wrapper.vcenter { align-items:center; }
#benchmarks H3	{ color: #101a46; font-family:Open Sans,sans-serif; text-transform:uppercase; font-size:24px; text-align:center; margin-bottom:0; width:100%; }
#benchmarks H4	{ font-family:Open Sans,sans-serif; text-transform:uppercase; font-size:24px; text-align:center; margin-bottom:5px; margin-top:0; width:100%; font-weight:500; }
#benchmarks .red	{ color:#c1002a; }
#benchmarks .section__image	{ margin-bottom:0; }

.section__lead {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  color: #101a46;
  max-width: 1000px;
}

.section__lead_color {
  color: #c1002a;
  font-weight: 600;
}

.section__content {
  padding-left: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.section_not-homepage {
  display: flex;
  flex-direction: column;
}

.section__cover {
  position: relative;
}

.section-heading__back-button {
  border: none;
  padding: 0;
  font-size: 25px;
  background: none;
  margin: 0 0 3px 20px;
  color: #fff;
  font-family: system-ui;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 40px;
}

.section__grid-block {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 40px;
}

.section__grid-block_reverse {
  grid-template-columns: 2fr 1fr;
}

.section__list {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  margin: 20px 0 20px 0;
}
.section__list ::marker {
  color: #c1002a;
  font-weight: 600;
}

.section__list-item {
  margin-bottom: 10px;
  max-width: 1000px;
}
.section__list-item:last-of-type {
  margin-bottom: 0;
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
  text-decoration: none;
  color: #000;
  background-color: #fff;
  width: 20%;
  margin-right: 20px;
}
.card:last-child {
  margin-right: 0;
}
.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 10px 5px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.card_hover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  transform: translateX(100%);
}

.card:hover .card_hover {
  transition: 0.2s;
  transform: translateX(0);
}

.card__heading {
  background-color: #c1002a;
  height: 70px;
  font-size: 18px;
  font-weight: 600;
  font-family: system-ui;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  text-decoration: none;
  margin: 0;
}

.card__selector {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-decoration: none;
  color: #fff;
  background-color: #1c3861;
  height: calc(50% - 35px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 10px;
  transition: 0.2s;
}
.card__selector:hover {
  background-color: #101a46;
  transition: 0.2s;
}
.card__selector:first-of-type {
  border-bottom: 1px solid #5d6971;
}

.card__title {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  color: #000;
  margin-bottom: 10px;
  max-width: 90%;
}

.card__num-wrapper {
  background-color: #c1002a;
  width: 40px;
  height: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
}
.card__num-wrapper span {
  margin: 0;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-size: 20px;
}
@media screen and (max-width: 1250px) {
  .card__num-wrapper {
    width: 25px;
    height: 25px;
  }
}

.card__description {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-bottom: 20px;
  color: #e48879;
}

.card__list {
  margin: 0 0 20px 0;
  padding-left: 15px;
}

.card__list-item {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
}
.card__list-item:last-of-type {
  margin-bottom: 0;
}

.infoblock {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  background: #fff;
  border: 1px solid #c5cacf;
  bottom: calc(50px + 5.3vw);
  left: 23%;
  border-radius: 4px;
  min-width: 150px;
  padding: 1px;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}
.infoblock:hover {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transition: 0.2s;
}

.infoblock__list {
  list-style: none;
  padding: 3px 0;
  margin: 0;
}

.infoblock__list-item {
  font-family: system-ui;
  font-size: 11px;
  font-weight: normal;
  text-align: left;
  margin-bottom: 3px;
  color: #4d4d4d;
}
.infoblock__list-item:last-of-type {
  margin-bottom: 0;
}
.infoblock__list-item a {
  text-decoration: none;
  color: inherit;
  transition: 0.2s;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.infoblock__list-item a:hover {
  color: #c1002a;
  transition: 0.2s;
}

.infoblock__trigger {
  color: #347AA2;
  opacity: 0.5;
  font-size: 0.7vw;
  position: absolute;
  cursor: pointer;
}

.infoblock__trigger:hover ~ .infoblock {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transition: 0.2s;
}

.infoblock__list-item__icon {
  margin-inline: 5px;
}

.accord {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
}

.accord__item {
  cursor: pointer;
  background: #fff !important;
  border-bottom: none;
  transition: 0.3s;
  overflow: hidden;
  position: relative;
}
.accord__item:nth-of-type(2n) {
  background: #f1f1f1;
}

.accord__heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;
  border: 1px solid #000;
  background: #101a46;
  transition: 0.3s;
  color: #fff;
  position: relative;
  z-index: 100;
  border-bottom: none;
}
.accord__heading:hover {
  background: #1c3861;
  transition: 0.3s;
}

.accord__heading_opened {
  background: #1c3861;
  transition: 0.3s;
  border-bottom: 1px solid #000;
}

.accord__title {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
}

.accord__content {
  opacity: 0;
  pointer-events: none;
  transform: translateY(-100%);
  transition: 0.3s;
  z-index: 99;
  max-height: 0;
}

.accord__content > .accord__heading {
  border-bottom: none;
}

.accord__content-item {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  padding: 20px 20px 20px 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.accord__content-item:nth-of-type(2n) {
  background: #f1f1f1;
}
.accord__content-item p {
  margin: 0;
  padding: 0;
}

.accord__content-item_subtitle {
  color: #c1002a;
  font-size: 20px;
  font-weight: 600;
}

.accord__content_opened {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
  transition: 0.3s;
  max-height: 5000px;
}

.popup {
  display: flex;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  transition: 0.5s;
}

.popup_opened {
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
  transition: 0.5s;
}

.popup__close-button {
  background: none;
  border: none;
  position: fixed;
  top: 20px;
  right: 20px;
  transition: 0.3s;
  font-size: 35px;
}
.popup__close-button:hover {
  transition: 0.3s;
  opacity: 0.7;
}

.popup__image {
  width: 90%;
}

.hint {
  position: fixed;
  width: 450px;
  bottom: 20px;
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0px 0px 19px 6px #6b6c6c;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.5s;
}

.hint__text {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

.hint_visible {
  opacity: 1;
  transition: 0.5s;
  visibility: visible;
  pointer-events: auto;
}

.hint__btn-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.hint__close-button {
  background: none;
  border: none;
  font-size: 35px;
  line-height: 0.6;
  top: -10px;
  right: -10px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  position: absolute;
  transition: 0.3s;
}
.hint__close-button:hover {
  transition: 0.3s;
  color: #c1002a;
}

.somecode	{ border:1px dashed #aaa; background:#eee; padding:20px 0; overflow-x:scroll; }
.code	{}
.code_wrapper	{ display:inline-block; min-width:100%; }
.line	{ padding:2px 20px; white-space:nowrap; }
.selectable	{ background:#f8f8f8; padding:2px 20px 2px 0; }

.tpl_download_wrapper	{ font-family:Open Sans,sans-serif; margin-top:40px; }
.tpl_download	{ display:inline-block; height:40px; line-height:40px; position:relative; border:1px solid #e48879; }
.tpl_download_wrapper .download_icon	{ display:block; position:absolute; left:10px; top:50%; transform:translateY(-50%); height:24px; }
.tpl_download_wrapper .select_list	{ position:absolute; left:0; bottom:100%; width:100%; text-align:center; display:none; background:#fff; border-left:1px solid #e48879; border-top:1px solid #e48879; border-right:1px solid #e48879; }
.tpl_download_wrapper.down .select_list	{ top:100%; bottom:auto; }
.tpl_download:hover .select_list	{ display:block; }
.tpl_download_wrapper A	{ color:#e48879; font-weight:bold; text-decoration:none; display:block; height:40px; text-align:center; border-bottom:1px solid #e48879; }
.tpl_download_wrapper A.title	{ color:#e48879; font-weight:bold; text-decoration:none; display:block; height:40px; text-align:center; border-bottom:1px solid #e48879; padding:0 20px 0 40px; position:relative; }
.tpl_download_wrapper A:hover	{ background:#e48879; color:#fff; }
.tpl_download_wrapper A .download_icon .fill	{ fill:#e48879; }
.tpl_download_wrapper A .download_icon .stroke	{ stroke:#e48879; }
.tpl_download_wrapper A:hover .download_icon .fill	{ fill:#fff; }
.tpl_download_wrapper A:hover .download_icon .stroke	{ stroke:#fff; }